<template>
  <BaseComponent>
    <h1>Main</h1>
  </BaseComponent>
</template>

<script lang="ts" setup>
useHead({
  title: "Dienst 3",
});
</script>
